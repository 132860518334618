import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import { SocketController } from '@bitcoin-portal/neko-web-sdk';

import { useTrackedState } from '@context/store';

import { useCexTokens } from '@views/Swap/context/providers/CexTokensProvider';

import { findProvider } from '@helpers/utils';

import { useDexTokens } from './DexTokensProvider';

type ExchangeRatesType = Record<string, Record<string, number>>;

type ExchangeRatesPayload = {
  baseCcy: string;
  quoteCcy: string;
  price: number;
};

const ratesReducer = (
  state: ExchangeRatesType,
  { baseCcy, quoteCcy, price }: ExchangeRatesPayload,
): ExchangeRatesType => ({
  ...state,
  [baseCcy]: {
    ...state?.[baseCcy],
    [quoteCcy]: price,
  },
});

const ExchangeRatesContext = createContext<ExchangeRatesType>({});

const ExchangeRatesProvider: FC<PropsWithChildren> = ({ children }) => {
  const ws = useRef<SocketController | null>(null);
  const { provider } = useTrackedState();
  const cexTokens = useCexTokens();
  const dexTokens = useDexTokens();
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
  const isUnmounted = useRef(false);

  const [rates, dispatchRates] = useReducer(ratesReducer, {});

  const tokens = useMemo(() => {
    const selectedProvider = findProvider(provider);
    return selectedProvider.isDeFi ? dexTokens : cexTokens;
  }, [provider, dexTokens, cexTokens]);

  const tickers = useMemo(
    () => tokens.map(t => t.abbr.toUpperCase()),
    [tokens],
  );

  const connectWebSocket = () => {
    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }

    if (isUnmounted.current) return; // Prevent connection if component is unmounted

    ws.current = new SocketController(
      'price',
      () => {
        if (!ws.current || ws.current.readyState !== ws.current.OPEN) return;

        const fiatList = ['USD'];

        ws.current.send(
          JSON.stringify({
            type: 'SUBSCRIBE',
            crypto: ['USD', ...new Set(tickers)],
            fiat: [...new Set(fiatList)],
          }),
        );
      },
      () => {
        console.log('WebSocket closed');
        ws.current = null;

        if (!isUnmounted.current) {
          if (reconnectTimeout.current) clearTimeout(reconnectTimeout.current);
          reconnectTimeout.current = setTimeout(connectWebSocket, 3000);
        }
      },
      message => {
        // console.log(message, 'message');
        if (typeof message.data !== 'string') return;
        const data = JSON.parse(message.data);

        if (data.message !== 'PRICE_UPDATE') return;

        dispatchRates(data);
      },
    );
  };

  useEffect(() => {
    isUnmounted.current = false;

    if (reconnectTimeout.current) clearTimeout(reconnectTimeout.current);
    reconnectTimeout.current = setTimeout(connectWebSocket, 500); // Delay to avoid flickering

    return () => {
      isUnmounted.current = true;
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, [tickers]);

  return (
    <ExchangeRatesContext.Provider value={rates}>
      {children}
    </ExchangeRatesContext.Provider>
  );
};

export const useExchangeRates = () => useContext(ExchangeRatesContext);

export default ExchangeRatesProvider;
