import React, { FunctionComponent } from 'react';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Swap from '@bitcoin-portal/verse-web-components/dist/Icons/Swap';

import { useMultichainProvider } from '@hooks/useMultichainProvider';

import Image from '../../Image';
import { MenuButton, SwapIconWrapper } from './styled';

interface Props {
  prov: IProviderOptionType;
  isCex?: boolean;
  onProviderSelect?: (provider: IProviderOptionType) => void;
}

const Provider: FunctionComponent<Props> = ({
  prov,
  isCex,
  onProviderSelect,
}) => {
  const { icon, label } = useMultichainProvider(prov);
  const location = useLocation();
  const earn = '/earn-bitcoin/';
  const isEarnPage = location.pathname.includes(earn);

  const redirect = async () => {
    if (isEarnPage) {
      if (prov.chain === 1) {
        await navigate(`${earn}eth/`);
      }
      if (prov.chain === 137) {
        await navigate(`${earn}matic/`);
      }
      if (prov.chain === 10000) {
        await navigate(`${earn}sbch/`);
      }
      if (prov.chain === undefined) {
        await navigate(`${earn}cex/`);
      }
    }
  };

  return (
    <MenuButton
      key={prov.provider}
      onClick={() => {
        redirect();
        if (onProviderSelect) {
          onProviderSelect(prov);
        }
      }}
    >
      {isCex ? (
        <SwapIconWrapper>
          <Swap size={12} />
        </SwapIconWrapper>
      ) : (
        <Image src={icon || ''} alt="provider icon" width={18} />
      )}
      {label}
    </MenuButton>
  );
};

export default Provider;
