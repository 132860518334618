import React from 'react';

import Intl from '@i18n/index';
import { GatsbyBrowser } from 'gatsby';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import theme from '@bitcoin-portal/verse-web-components/dist/themes/verse';

import Layout from '@components/Layout';
import VerseAIChatBot from '@components/VerseAIChatBot';

import EnsProvider from '@context/providers/EnsProvider';
import ExchangeRatesProvider from '@context/providers/ExchangeRatesProvider';
import { FarmsProvider } from '@context/providers/FarmsProvider';
// import FarmsApyProvider from '@context/providers/FarmsApyProvider';
import LiquidityPairsProvider from '@context/providers/LiquidityPairsProvider';

import { CexTokensProvider } from '@views/Swap/context/providers/CexTokensProvider';
import { SwapProvider } from '@views/Swap/context/providers/SwapProvider';

import BcomAccountsProvider from './providers/BcomAccountsProvider';
import DexRatesProvider from './providers/DexRatesProvider';
import DexTokensProvider from './providers/DexTokensProvider';
import VerseBalancesProvider from './providers/VerseBalancesProvider';
import VerseClaimMerkleProvider from './providers/VerseClaimMerkleProvider';
import { Provider } from './store';

const independentPages = ['/card/'];

const WrapPageElement: GatsbyBrowser<
  null,
  { locale: string }
>['wrapPageElement'] = ({ props, element }) => {
  if (independentPages.some(p => props.location.pathname.startsWith(p))) {
    return (
      <Intl
        locale={props.pageContext.locale}
        localeDir={props.location.pathname}
      >
        <ThemeProvider theme={theme as DefaultTheme}>{element}</ThemeProvider>
      </Intl>
    );
  }

  return (
    <BcomAccountsProvider>
      <VerseBalancesProvider>
        <VerseClaimMerkleProvider>
          <EnsProvider>
            <SwapProvider>
              <Intl
                locale={props.pageContext.locale}
                localeDir={props.location.pathname}
              >
                <ThemeProvider theme={theme as DefaultTheme}>
                  <Provider>
                    <LiquidityPairsProvider>
                      <DexRatesProvider>
                        <DexTokensProvider>
                          <CexTokensProvider>
                            <ExchangeRatesProvider>
                              <FarmsProvider>
                                <VerseAIChatBot>
                                  <Layout location={props.location}>
                                    {element}
                                  </Layout>
                                </VerseAIChatBot>
                              </FarmsProvider>
                            </ExchangeRatesProvider>
                          </CexTokensProvider>
                        </DexTokensProvider>
                      </DexRatesProvider>
                    </LiquidityPairsProvider>
                  </Provider>
                </ThemeProvider>
              </Intl>
            </SwapProvider>
          </EnsProvider>
        </VerseClaimMerkleProvider>
      </VerseBalancesProvider>
    </BcomAccountsProvider>
  );
};

export default WrapPageElement;
