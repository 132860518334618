import React, { FC, PropsWithChildren } from 'react';

import { Script } from 'gatsby';

import { useFeatureFlag } from '@helpers/featureFlags';

const VerseAIChatBot: FC<PropsWithChildren> = ({ children }) => {
  const { isEnabled } = useFeatureFlag('verseAIChatBot');

  return (
    <>
      {isEnabled && (
        <>
          <Script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1" />
          {/* @ts-expect-error web component */}
          <df-messenger
            chat-icon="https:&#x2F;&#x2F;analytics.verse.bitcoin.com&#x2F;logo.png"
            intent="WELCOME"
            chat-title="VerseBot"
            agent-id="3b25606a-51fc-4b95-9ecf-5d67e8749a68"
            language-code="en"
          />
        </>
      )}
      {children}
    </>
  );
};

export default VerseAIChatBot;
