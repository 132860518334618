import React, { FunctionComponent, useEffect, useRef } from 'react';

import { RouteComponentProps, navigate, useParams } from '@reach/router';

import { findProvider, providerFromPath } from '../../helpers';

import { useDispatch, useTrackedState } from '../store';
import { TExchangeProvider } from '../types';

interface Props extends RouteComponentProps {
  page?: string;
}

export const ChainValidationHandler: FunctionComponent<Props> = ({
  page = 'pools',
}) => {
  const params = useParams();
  const chain = params?.chain || '';

  const { provider: exchangeProvider } = useTrackedState();
  const dispatch = useDispatch();

  const initialRender = useRef(true);

  const provider = findProvider(exchangeProvider);

  const redirectPage = async (chainParam: string) => {
    await navigate(`/${page}/${chainParam}/`);
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (exchangeProvider) {
      const newProvider = findProvider(exchangeProvider);
      const { chainParam } = newProvider || {
        chainParam: 'eth',
      };
      if (chainParam) redirectPage(chainParam);
    }
  }, [exchangeProvider]);

  useEffect(() => {
    if (['eth', 'sbch', 'seth', 'heth', 'cex', 'matic'].includes(chain)) {
      const urlProvider = providerFromPath(chain);
      if (urlProvider.name !== exchangeProvider) {
        dispatch({
          type: 'SET_PROVIDER',
          payload: urlProvider.name as TExchangeProvider,
        });
      }
    } else {
      const { chainParam } = provider || {
        chainParam: 'eth',
      };
      if (chainParam) redirectPage(chainParam);
    }
  }, [chain, page]);

  return <></>;
};

export default ChainValidationHandler;
