import styled, { css } from 'styled-components';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import Paragraph from '@bitcoin-portal/verse-web-components/dist/Paragraph';
import { media } from '@bitcoin-portal/verse-web-components/dist/themes/breakpoints';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import { media as dashboardMedia } from '../Dashboard/media';

// LoginButton
// eslint-disable-next-line prettier/prettier
export const StyledButton = styled(Button)<{
  wider?: boolean;
  chainImg?: boolean;
  connected?: boolean;
}>`
  ${({ wider, chainImg, connected }) => css`
    display: none !important;
    gap: 2px;
    border-radius: 18px;
    width: ${wider ? '150px' : 'fit-content'};
    display: flex !important;

    height: 36px;
    padding: ${chainImg ? 'none' : '0px 16px'};
    white-space: nowrap;

    & > span {
      &:first-child {
        max-width: ${connected ? '50px' : '120px'};
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        height: ${({ theme }) => theme.spacing.unit * 3}px;
        span.lazy-load-image-loaded {
          height: ${({ theme }) => theme.spacing.unit * 3}px !important;
          &:nth-child(2) {
            margin-left: -${({ theme }) => theme.spacing.unit}px;
          }
        }
      }
      ${media.md`
        &:first-child {
          max-width: 120px;
        }
      `}
    }

    ${media.sm`
      display: flex !important;
      width: 117px;
      ${
        wider &&
        css`
          width: fit-content;

          & > span {
            &:first-child {
              max-width: unset;
            }
          }
        `
      }
    `}
    ${media.md`
      gap: 8px;
      `}
  `}
`;

export const Heading = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 0.5}px;
`;

export const Purpose = styled(Paragraph)`
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
  font-size: 12px;
  font-weight: 400;

  & > a {
    color: ${colors.verseBlue};
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
  & > div {
    margin-bottom: ${({ theme }) => theme.spacing.unit}px;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.shade30};
`;

export const ModalWrapper = styled.div<{
  $isDashboard: boolean;
}>`
  ${({ $isDashboard }) =>
    $isDashboard &&
    css`
      ${dashboardMedia.portrait} {
        #outer {
          width: calc(100vw - 200px);
          left: 200px;
          #inner {
            top: 140px;
            height: unset;
            max-height: calc(100vh - 280px);
            border-radius: 25px;
          }
        }
      }

      @media (min-width: ${dashboardMedia.large}) {
        #outer {
          width: calc(100vw - 240px);
          left: 240px;
        }
      }
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  top: -11px;
  right: 2px;
  /* background: #202B58; */
  background: black;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
